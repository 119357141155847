import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import tilt_border from "../../../assets/tilt_border.png";
import tilt_border_footer from "../../../assets/tilt_border_footer.png";
import Excerpt from "../excerpt";

interface ServiceImageContainerProps {
  backgroundAttachmentString?: string;
  className?: string;
  excerpt?: string;
  highlight?: string;
  excerptEnd?: string;
}

interface ServiceImageContainerStates {
  animationEnd: boolean;
  oldScroll: number;
  scrollDir: boolean;
}

class ServiceImageContainer extends React.Component<
  ServiceImageContainerProps,
  ServiceImageContainerStates
> {
  constructor(props: ServiceImageContainerProps) {
    super(props);
    this.state = { scrollDir: true, oldScroll: 0, animationEnd: false };
  }

  public componentDidMount = () => {
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("animationstart", this.handleAnimationStart);
    window.addEventListener("animationend", this.handleAnimationEnd);
  };

  public componentWillUnmount = () => {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("animationstart", this.handleAnimationStart);
    window.removeEventListener("animationend", this.handleAnimationEnd);
  };

  public render() {
    return (
      <div style={{ overflow: `hidden` }}>
        <div
          style={{
            marginBottom: `-7.5%`,
            position: `absolute`,
            width: `100%`,
            zIndex: 5
          }}
        >
          <img
            className="container-footer"
            alt="Top Border"
            src={tilt_border_footer}
          />
        </div>{" "}
        <div
          className={this.props.className}
          style={
            this.state.scrollDir
              ? {
                  animationName: "grow",
                  backgroundAttachment: this.props.backgroundAttachmentString
                }
              : {
                  animationName: "shrink",
                  backgroundAttachment: this.props.backgroundAttachmentString
                }
          }
        >
          <Excerpt
            excerpt={this.props.excerpt}
            excerptEnd={this.props.excerptEnd}
            highlight={this.props.highlight}
            scrollDir={this.state.scrollDir}
          />
        </div>
        <div
          style={{
            marginTop: `-7.5%`,
            overflow: `hidden`,
            position: `absolute`,
            width: `100%`
          }}
        >
          <img
            className="container-header"
            alt="Bottom Border"
            src={tilt_border}
          />
        </div>
      </div>
    );
  }

  private handleAnimationEnd = () => {
    this.setState({ animationEnd: true });
  };

  private handleAnimationStart = () => {
    this.setState({ animationEnd: false });
  };

  private handleScroll = () => {
    const newScroll = window.pageYOffset;
    if (this.state.oldScroll - newScroll < -100) {
      this.setState({ scrollDir: true });
    } else if (this.state.oldScroll - newScroll > 100) {
      this.setState({ scrollDir: false });
    }
    this.setState({ oldScroll: newScroll });
  };
}

export default ServiceImageContainer;
