import React from "react";
import { Trans } from "react-i18next";
import ProcessIcon1 from "../../../assets/surya_1.png";
import ProcessIcon2 from "../../../assets/surya_2.png";
import Container from "../container";
import ServicesFourFeatures from "./servicesFourFeatures";
import ServicesImageContainer from "./servicesImageContainer";

interface ServiceImageContainerProps {
  backgroundAttachmentString?: string;
}

class Services2 extends React.Component<ServiceImageContainerProps, {}> {
  public render() {
    return (
      <div id="services2" className="services2">
        <ServicesImageContainer
          backgroundAttachmentString={this.props.backgroundAttachmentString}
          className="background-image2"
          excerpt="services1_excerpt_white"
          highlight="services1_excerpt_highlight"
          excerptEnd="services1_excerpt_end"
        />
        <Container containerTitle="service1_header">
          <div
            className="feature-container-div"
            style={{ textAlign: `center` }}
          >
            <ServicesFourFeatures />
          </div>
          <h2 style={{ marginTop: `-5px` }}>
            <Trans i18nKey="services1_mid_header" />
          </h2>
          <div className="services1-processes-div">
            <div className="services1-processes">
              <img
                className="services1-processes-image"
                src={ProcessIcon1}
                alt="Lifecycle Stage 1"
              />
              <div className="services1-processes-header">
                <h3>
                  <Trans i18nKey="service1_process1_header" />
                  <span style={{ color: "#4cc5dc" }}>
                    <Trans i18nKey="service1_process1_header_highlight" />
                  </span>
                </h3>
                <p>
                  <Trans i18nKey="service1_process1_text" />
                </p>
              </div>
            </div>
            <div className="services1-processes">
              <img
                className="services1-processes-image"
                src={ProcessIcon2}
                alt="Lifecycle Stage 2"
              />
              <div className="services1-processes-header">
                <h3>
                  <Trans i18nKey="service1_process2_header" />
                  <span style={{ color: "#4cc5dc" }}>
                    <Trans i18nKey="service1_process2_header_highlight" />
                  </span>
                </h3>
                <p>
                  <Trans i18nKey="service1_process2_text" />
                </p>
              </div>
            </div>
          </div>
          <p
            className="services-header-lower"
            style={{ paddingBottom: `24px` }}
          >
            <Trans i18nKey="service1_lower_text_white" />
            {
              <span className="colored-p" style={{ display: `block` }}>
                <Trans i18nKey="service1_lower_text_highlight" />
              </span>
            }
          </p>
        </Container>
      </div>
    );
  }
}

export default Services2;
