import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import React from "react";
import tilt_border from "../../../assets/tilt_border.png";
import Excerpt from "../excerpt";

export default ({
  className,
  excerpt,
  highlight,
  excerptEnd,
  scrollId
}: {
  className?: string;
  excerpt?: string;
  highlight?: string;
  excerptEnd?: string;
  scrollId?: string;
}) => {
  return (
    <div style={{ overflow: `hidden` }}>
      <div className={className}>
        <Excerpt
          excerpt={excerpt}
          excerptEnd={excerptEnd}
          highlight={highlight}
          style={{
            animation: null,
            bottom: `unset`,
            top: `14rem`
          }}
        />
      </div>
      <div
        style={{
          marginTop: `-7.65%`,
          position: `absolute`,
          width: `100%`
        }}
      >
        <img
          className="container-header"
          alt="Bottom Border"
          style={{ animation: `neutral 5s forwards` }}
          src={tilt_border}
        />
      </div>
    </div>
  );
};
