import { graphql, useStaticQuery } from "gatsby";
import Img, { FluidObject } from "gatsby-image";
import React, { CSSProperties } from "react";
import { useTranslation } from "react-i18next";
import FeatureContainerFour from "../featureContainerFour";

export default () => {
  const { t } = useTranslation();
  return (
    <div className="four-features-grid">
      <FeatureContainerFour
        headerIconSource={require(`../../../assets/service_icon_1.png`)}
        excerpt={"service_features_41"}
      />
      <FeatureContainerFour
        headerIconSource={require(`../../../assets/service_icon_2.png`)}
        excerpt={"service_features_42"}
      />
      <FeatureContainerFour
        headerIconSource={require(`../../../assets/service_icon_3.png`)}
        excerpt={"service_features_43"}
      />
      <FeatureContainerFour
        headerIconSource={require(`../../../assets/service_icon_4.png`)}
        excerpt={"service_features_44"}
      />
    </div>
  );
};
