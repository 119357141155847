import React from "react";
import { Trans } from "react-i18next";
import Container from "../container";
import "../layout.css";
import ServicesImageContainerWithHeaderOnly from "./servicesImageContainerWithHeaderOnly";
import ServicesThreeFeatures from "./servicesThreeFeatures";

class Services1 extends React.Component {
  public render() {
    return (
      <div id="services1" className="services1">
        <ServicesImageContainerWithHeaderOnly
          className="background-image1"
          excerpt="service2_excerpt_white"
          highlight="service2_excerpt_highlight"
          excerptEnd="service2_excerpt_end"
        />
        <Container>
          <h3 className="service2-header-h3">
            <Trans i18nKey="service2_header" />
          </h3>
          <div
            className="feature-container-div"
            style={{ textAlign: `center` }}
          >
            <ServicesThreeFeatures />
          </div>
          <p
            className="services-header-lower"
            style={{
              marginTop: `-15px`,
              paddingBottom: `25px`,
              paddingTop: `0px`
            }}
          >
            <Trans i18nKey="service2_lower_text_white" />
            {
              <span className="colored-p" style={{ display: `block` }}>
                {" "}
                <Trans i18nKey="service2_lower_text_highlight" />
              </span>
            }
          </p>
        </Container>
      </div>
    );
  }
}

export default Services1;
