import React from "react";
import { Trans } from "react-i18next";
import DataImg from "../../../assets/data-img.png";
import Container from "../container";
import ServicesImageContainer from "./servicesImageContainer";

interface ServiceImageContainerProps {
  backgroundAttachmentString?: string;
}

class Services3 extends React.Component<ServiceImageContainerProps, {}> {
  public render() {
    return (
      <div id="services3">
        <ServicesImageContainer
          backgroundAttachmentString={this.props.backgroundAttachmentString}
          className="background-image3"
          excerpt="service3_excerpt_white"
          highlight="service3_excerpt_highlight"
        />
        <Container>
          <div className="services3">
            <div className="services3-left-div">
              <h3 style={{ padding: `15px 0 35px`, margin: `0` }}>
                Data Scientists
              </h3>
              <p>
                <Trans i18nKey="The" />
                {
                  <span className="colored-p">
                    <Trans i18nKey="service3_para1_bangalore_uni" />
                  </span>
                }{" "}
                <Trans i18nKey="service3_para1_end" />
              </p>
              <p>
                <Trans i18nKey="service3_para2" />
              </p>
              <p>
                <Trans i18nKey="service3_para3" />
              </p>
              <p>
                <Trans i18nKey="service3_para4" />
              </p>
            </div>
            <div className="services3-image-div">
              <img
                className="sevices3-image"
                style={{ maxWidth: `100%` }}
                src={DataImg}
                alt="Service 3"
              />
            </div>
          </div>
        </Container>
      </div>
    );
  }
}

export default Services3;
