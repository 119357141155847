import React from "react";
import { Trans } from "react-i18next";
import MicrosoftDynamic from "../../../assets/microsoft_dynamic.png";
import ContainerWithHeaderOnly from "../containerWithHeaderOnly";
import ServicesImageContainer from "./servicesImageContainer";

interface ServiceImageContainerProps {
  backgroundAttachmentString?: string;
}

class Services4 extends React.Component<ServiceImageContainerProps, {}> {
  public render() {
    return (
      <div id="services4">
        <ServicesImageContainer
          backgroundAttachmentString={this.props.backgroundAttachmentString}
          className="background-image4"
          excerpt="service4_excerpt_white"
          highlight="service4_excerpt_highlight"
        />
        <ContainerWithHeaderOnly>
          <div className="services-outer-div">
            <div className="services4">
              <h3 style={{ marginBottom: `0` }}>
                <Trans i18nKey="service4_header" />
              </h3>
              <p>
                <Trans i18nKey="service4_para" />{" "}
                {
                  <span className="colored-p" style={{ display: "block" }}>
                    <Trans i18nKey="service4_para_highlight" />
                  </span>
                }
              </p>
              <p>
                <Trans i18nKey="service4_para_ul" />
              </p>
              <ul>
                <li className="services4-li">
                  <Trans i18nKey="service4_li1" />
                </li>
                <li className="services4-li">
                  <Trans i18nKey="service4_li2" />
                </li>
                <li className="services4-li">
                  <Trans i18nKey="service4_li3" />
                </li>
                <li className="services4-li">
                  <Trans i18nKey="service4_li4" />
                </li>
              </ul>
              <p style={{ color: `#4cc5dc` }}>
                <Trans i18nKey="service4_lower_highlight" />
              </p>
            </div>
            <div className="services4-image-div">
              <img
                alt="Microsft Dynamics"
                src={MicrosoftDynamic}
                style={{ width: `100%` }}
              />
            </div>
          </div>
        </ContainerWithHeaderOnly>
      </div>
    );
  }
}

export default Services4;
