import React from "react";
import { isIOS, isIOS13, isIPad13, isMobile } from "react-device-detect";
import { withTranslation, WithTranslation } from "react-i18next";
import Layout, { scrollDir } from "../components/layout";
import "../components/layout.css";
import Services1 from "../components/services/services-1";
import Services2 from "../components/services/services-2";
import Services3 from "../components/services/services-3";
import Services4 from "../components/services/services-4";

interface ServicesStates {
  backgroundAttachmentString?: string;
}

// tslint:disable-next-line: no-empty-interface
interface ServicesProps extends WithTranslation {}

class Services extends React.Component<ServicesProps, ServicesStates> {
  constructor(props: ServicesProps) {
    super(props);
    this.state = {
      backgroundAttachmentString: `inherit`
    };
  }

  public componentDidMount() {
    this.setState({
      backgroundAttachmentString:
        isMobile || isIOS || isIPad13 || isIOS13 ? "inherit" : "fixed"
    });
  }

  public render() {
    return (
      <Layout>
        <Services1 />
        <Services2
          backgroundAttachmentString={this.state.backgroundAttachmentString}
        />
        <Services3
          backgroundAttachmentString={this.state.backgroundAttachmentString}
        />
        <Services4
          backgroundAttachmentString={this.state.backgroundAttachmentString}
        />
      </Layout>
    );
  }
}

export default withTranslation()(Services);
