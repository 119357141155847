import React from "react";
import { useTranslation } from "react-i18next";
import FeatureContainerThree from "../featureContainerThree";

export default () => {
  const { t } = useTranslation();
  return (
    <div className="three-features-grid">
      <FeatureContainerThree
        excerpt={"service_features_31"}
        headerIconSource={require(`../../../assets/star_1.png`)}
      />
      <FeatureContainerThree
        excerpt={"service_features_32"}
        headerIconSource={require(`../../../assets/star_2.png`)}
      />
      <FeatureContainerThree
        excerpt={"service_features_33"}
        headerIconSource={require(`../../../assets/star_3.png`)}
      />
    </div>
  );
};
